/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable spaced-comment */
import {
  ModalContent,
  Card,
  Common,
  Icon,
  DeliveryStatusLabel,
} from "@/components";
import { manifest, route } from "../models";
import { useEffect, useState, useMemo } from "react";
import { useSwapRoute } from "../services/deliveryManifest";
import { SnackbarUtils } from "@/utils";
import { useAppDispatch } from "@/sharedStore/hooks";
import { RouteCombobox } from "./RouteCombobox";
import { useGetRouteList } from "../providers/deliveryManifest/hooks";
import {
  CanModifyRouteByStatus,
  DeliveryStatusValue,
} from "@/configuration/globalVariable";
import clsx from "clsx";

interface Props {
  open: boolean;
  onClose: () => void;
  currentData: manifest;
  manifests: manifest[];
  onSubmit: (position: number) => void;
  onCompleted: () => void;
}

export const ModalSwap = ({
  open,
  onSubmit,
  onClose,
  currentData,
  manifests,
  onCompleted,
}: Props) => {
  const data = useGetRouteList();
  const swapRoute = useSwapRoute();
  const [routeSelected, setRouteSelected] = useState<route>(currentData.route);
  const { isLoading } = swapRoute;
  const dispatch = useAppDispatch();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [routes, setRoutes] = useState<route[]>([]);

  const handleClose = () => {
    onClose();
    onSubmit(1);
  };
  const handleCompleted = () => {
    onCompleted();
  };

  useEffect(() => {
    setRoutes(data);
  }, [data]);

  useEffect(() => {
    setRouteSelected(currentData.route);
  }, [currentData]);

  const filteredRoutes = useMemo(() => {
    const rs = routes
      ? routes.filter(
          (item: route) =>
            item.name.toUpperCase().indexOf(searchKeyword.toUpperCase()) > -1 ||
            item.name.toUpperCase().indexOf(searchKeyword.toUpperCase()) > -1
        )
      : [];
    return rs;
  }, [routes, searchKeyword]);

  const handleChange = (route: route) => {
    setRouteSelected(route);
  };

  const handleSave = () => {
    swapRoute.mutate(
      {
        manifestId: currentData.id,
        destRouteId: routeSelected.id,
      },
      {
        onSuccess(data) {
          SnackbarUtils.success(
            `All items of route <b>${currentData.route.name}</b> has been swapped to route <b>${routeSelected.name}</b> successfully.`
          );

          handleClose();
          handleCompleted();
        },
      }
    );
  };

  const RouteItem = ({
    isCurrent,
    isActive,
    data,
    disable,
    onClick,
  }: {
    isCurrent?: boolean;
    isActive?: boolean;
    disable?: boolean;
    data: route;
    onClick: () => void;
  }) => {
    const isNoAction = isCurrent || disable || isActive;
    return (
      <div
        className={clsx(
          "text-sm flex items-center mb-0.5 p-3",
          !isNoAction && "hover:bg-neutral-10 cursor-pointer",
          isCurrent && "bg-neutral-20",
          isActive && "bg-neutral-10",
          disable && "bg-neutral-5"
        )}
        onClick={() => (isNoAction ? {} : onClick())}
      >
        <div>
          <Common.DotColor style={{ backgroundColor: data.colour }} />
        </div>
        <span className="font-semibold mx-1.5 line-clamp-1">{data.name}</span>
        {data.status !== DeliveryStatusValue.PENDING && (
          <DeliveryStatusLabel status={data.status} className="mr-2" />
        )}
        {isCurrent && (
          <span className="text-neutral-50 whitespace-nowrap mr-2">
            ( Current )
          </span>
        )}
        {isActive && (
          <div className="ml-auto">
            <Icon.Checked size="14" className="fill-blue" />
          </div>
        )}
      </div>
    );
  };

  return (
    <ModalContent
      open={open}
      onClose={handleClose}
      panelClassWidth="max-w-[30rem] w-full"
    >
      {routes && routeSelected && (
        <Card title={"Swap route"}>
          <div className="p-4">
            <Common.Input
              iconRight={Icon.Search}
              iconType="stroke"
              placeholder="Search route name"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
              onClear={() => setSearchKeyword("")}
            />
            <div className="h-52 overflow-y-auto mt-3">
              {manifests && manifests.length ? (
                <>
                  {filteredRoutes.length ? (
                    filteredRoutes.map((route: route) => {
                      const disabled = !CanModifyRouteByStatus.includes(
                        route.status
                      );
                      return (
                        <RouteItem
                          key={route.id}
                          data={route}
                          onClick={() => handleChange(route)}
                          isCurrent={currentData.id === route.id}
                          isActive={routeSelected?.id === route.id}
                          disable={disabled}
                        />
                      );
                    })
                  ) : (
                    <Common.NoData title={"No Route founded"} />
                  )}
                </>
              ) : (
                <Common.NoData title={"No Route data!"} />
              )}
            </div>
          </div>

          <div className="shadow-overline px-4 py-3 flex space-x-2 justify-end">
            <Common.Button
              disabled={isLoading}
              onClick={handleClose}
              color="transparent"
            >
              Cancel
            </Common.Button>
            <Common.Button
              disabled={isLoading}
              isLoading={isLoading}
              onClick={() => handleSave()}
            >
              Save
            </Common.Button>
          </div>
        </Card>
      )}
    </ModalContent>
  );
};
