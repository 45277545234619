export const dropdownIds = {
  MOVETR: "move_to_another_route",
  CHANGE_POSITION: "change_position",
  UNALLOCATE: "unallocate",
  SWAP_ROUTE: "swap_route",
  CHANGE_DRIVER: "change_driver",
};

export const manifestIds = {
  UNALLOCATED: "unallocated",
};

export const capacityTypes = [
  {
    id: 0,
    text: "Not Applicable",
    value: 0,
  },
  {
    id: 1,
    text: "Total Order Amount",
    value: 1,
  },
  // {
  //   id: 2,
  //   text: "Weight and Volume",
  //   value: 2,
  // },
  {
    id: 3,
    text: "Total Amount and Number of Orders",
    value: 3,
  },
];
