/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import { useEffect, useMemo, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import clsx from "clsx";
import { Common, Icon, ModalInform } from "@/components";
import {
  PATH,
  TIME_FORMAT,
  CanModifyRouteByStatus,
  DeliveryStatusValue,
} from "@/configuration/globalVariable";
import { useAppDispatch, useAppSelector } from "@/sharedStore/hooks";
import { GoogleMap } from "./Map";
import { ModalOptimize } from "./ModalOptimize";
import { DeliveryList } from "./DeliveryList";
import { StorageAddress } from "./StorageAddress";

import {
  clearManifest,
  moveAndApplyManifestLine,
  moveUnallocatedAndApplyManifestLine,
  moveUnallocatedManifestLineInOptimizeGroup,
  replaceManifestsUpdatedETAs,
  updateUnallocatedDocuments,
} from "../providers/deliveryManifest/slice";
import { fetchAllManifestByDateAsync } from "../providers/deliveryManifest/sliceServices";
import {
  manifestsSelector,
  unallocatedDocumentCountSelector,
  deliveryManifestLoading,
  storageAddressSelector,
  deliveryManifestLoaded,
  optimizingGroupsSelector,
  unallocatedDocumentsSelector,
} from "../providers/deliveryManifest/selector";
import {
  manifest,
  manifestLine,
  // solution,
  storageAddress,
  // visits,
} from "../models/DeliveryManifest";
import {
  finishPendingRequests,
  getRouteETAWithMapIds,
  useGetUnallocatedList,
  useSaveEditingRoutes,
} from "../services/deliveryManifest";
import {
  checkCompletedDocumentPosition,
  groupUnallocatedManifest,
  isUnallocatedManifest,
  // hoursToISO,
  mergeETAsIntoRoutes,
  refillManifestLinePosition,
} from "../utils";
import {
  useGetETAByManifestId,
  useGetRouteById,
} from "../providers/deliveryManifest/hooks";
import { ModalMoveRouteEditScreen } from "./ModalMoveRouteEditScreen";
import { SnackbarUtils } from "@/utils";
import { ModalChangeDriver } from "./ModalChangeDriver";
import { ModalSwap } from "./ModalSwap";
import { ModalManifestMove } from "./ModalManifestMove";
import { userSelector } from "@/providers/auth/selector";
import { manifestIds } from "../configVariable";
import ReactTooltip from "react-tooltip";

export const TabAllocation = ({ date, setDate }: any) => {
  const [modalConfirmCancel, setModalConfirmCancel] = useState<
    "refreshList" | "changeDate" | "edit" | string
  >("");
  const [startDate, setStartDate] = useState(date);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isOpenOptimizeModal, setOpenOptimizeModal] = useState(false);
  const [isChangeDriverModal, setChangeDriverModal] = useState<manifest>(
    {} as manifest
  );
  const [isSwapModal, setSwapModal] = useState<manifest>({} as manifest);
  const [isManifestMoveModal, setManifestMoveModal] = useState<manifest>(
    {} as manifest
  );
  const [isHideEmptyRoute, setHideEmptyRoute] = useState(true);
  const [isETALoading, setETALoading] = useState(false);

  const [routesSelected, setRoutesSelected] = useState<string[]>([]);
  const [manifestsOptimizing, setManifestsOptimizing] = useState<manifest[]>(
    []
  );
  const [routesStoppedSelected, setRoutesStoppedSelected] = useState<string[]>(
    []
  );
  const [unallocateChecked, setUnallocateChecked] = useState(false);

  const [manuallyOptimizedManifestLines, setManuallyOptimizedManifestLines] =
    useState<manifestLine[]>([]);
  const [previewingOptimizeGroup, setPreviewingOptimizeGroup] = useState<any>();

  const user = useAppSelector(userSelector);
  const manifests = useAppSelector(manifestsSelector) || [];
  const unallocatedDocuments = useAppSelector(unallocatedDocumentsSelector);
  const isDeliveryManifestLoaded = useAppSelector(deliveryManifestLoaded);
  const loading = useAppSelector(deliveryManifestLoading);
  const unallocatedDocumentCount = useAppSelector(
    unallocatedDocumentCountSelector
  );
  const { mutate: fetchUnallocatedList } = useGetUnallocatedList();
  const getRouteById = useGetRouteById();
  const getETAByManifestId = useGetETAByManifestId();

  const storageAddress = useAppSelector(
    storageAddressSelector
  ) as storageAddress;

  const optimizingGroups = useAppSelector(optimizingGroupsSelector);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const handleChangeDateToFetch = useCallback((date: Date) => {
    setStartDate(date);
  }, []);

  // if (isHideEmptyRoute) {
  //   return item.manifestLines.length;
  // }
  const results = useMemo(
    () =>
      manifests
        ? manifests
            .filter((item: manifest) =>
              isHideEmptyRoute ? item?.manifestLines?.length : true
            )
            .filter((item: manifest) => {
              if (
                item.route?.name &&
                item.route?.name
                  .toUpperCase()
                  .indexOf(searchKeyword.toUpperCase()) > -1
              )
                return true;
              if (
                item.driver?.givenName &&
                item.driver?.givenName
                  .toUpperCase()
                  .indexOf(searchKeyword.toUpperCase()) > -1
              )
                return true;
              if (
                item.driver?.lastName &&
                item.driver?.lastName
                  .toUpperCase()
                  .indexOf(searchKeyword.toUpperCase()) > -1
              )
                return true;
              if (item.manifestLines.length) {
                return item.manifestLines.filter(
                  (manifestLine: manifestLine) =>
                    manifestLine.document.customerTradingName &&
                    manifestLine.document.customerTradingName
                      .toUpperCase()
                      .indexOf(searchKeyword.toUpperCase()) > -1
                ).length;
              }
              return false;
            })
            .sort((a: manifest, b: manifest) => {
              if (a.route.name < b.route.name) {
                return -1;
              }
              if (a.route.name > b.route.name) {
                return 1;
              }
              return 0;
            })
        : [],
    [searchKeyword, manifests, isHideEmptyRoute]
  );

  const unallocatedManifest = useMemo(() => {
    return unallocatedDocuments?.length
      ? groupUnallocatedManifest(unallocatedDocuments)
      : undefined;
  }, [unallocatedDocuments]);

  const manifestsToDisplayOnMap = useMemo(
    () => [
      ...results,
      ...(manifestsOptimizing.length ? manifestsOptimizing : []),
      ...(unallocatedManifest ? [unallocatedManifest] : []),
    ],
    [results, manifestsOptimizing, unallocatedManifest]
  );

  const manifestIdsCanMove = useMemo(() => {
    return manifestsOptimizing.length
      ? results
          .filter((m) => !manifestsOptimizing.some((mo) => mo.id === m.id))
          .map((m) => m.id)
      : undefined;
  }, [manifestsOptimizing, results]);

  const handleEditRoutes = () => {
    const routesActive = [] as manifest[];
    const routesUnActive = [] as manifest[];
    manifests.map((manifest: manifest) => {
      if (routesSelected.includes(manifest.id)) {
        routesActive.push(manifest);
      }
      if (
        !(
          !routesSelected.includes(manifest.id) &&
          CanModifyRouteByStatus.includes(manifest.status)
        )
      ) {
        routesUnActive.push(manifest);
      }
      return manifest;
    });

    navigate(`/${PATH.DELIVERY._}/${PATH.DELIVERY.EDIT_ROUTES}`, {
      state: {
        unallocate: unallocateChecked,
        deliveryDate: date,
        routesActive: unallocateChecked
          ? [{}].concat(routesActive)
          : routesActive,

        routesUnActive,
      },
    });
  };

  const handleSubmitOptimize = () => {
    setOpenOptimizeModal(false);
  };

  const fetchManifestList = (date: any) => {
    finishPendingRequests("RefreshList");
    setManifestsOptimizing([] as manifest[]);
    setUnallocateChecked(false);
    dispatch(clearManifest());
    dispatch(
      fetchAllManifestByDateAsync({
        date: moment(date).format(TIME_FORMAT.REQUEST),
        isFetchETA: true,
      })
    ).then((mnf) => {
      const manifestsPayload = mnf.payload.manifests;
      if (manifestsPayload && manifestsPayload.length) {
        handleFetchandMergeETAIntoRoutes(manifestsPayload);
      }
    });
    fetchUnallocatedList(moment(date).format(TIME_FORMAT.REQUEST), {
      onSuccess: (data) => {
        dispatch(updateUnallocatedDocuments(data.unAllocatedDocs));
      },
    });
  };

  const handleChangeDate = (date: any) => {
    finishPendingRequests("changeDate");
    setDate(date);
    setManifestsOptimizing([]);
    setRoutesSelected([]);
    setRoutesStoppedSelected([]);
    fetchManifestList(date);
    setUnallocateChecked(false);
  };

  useEffect(() => {
    if (!isDeliveryManifestLoaded) {
      fetchManifestList(location.state?.deliveryDate || date);
      if (location.state?.deliveryDate) {
        setDate(new Date(location.state?.deliveryDate));
        window.history.replaceState({}, document.title);
      }
    }
    return () => {
      // clear checkbox component didmount
      finishPendingRequests("cancelFetchETAs");
      setRoutesSelected([]);
      setRoutesStoppedSelected([]);
    };
    // eslint-disable-next-line
  }, []);

  const handleFetchandMergeETAIntoRoutes = (manifestsPayload: manifest[]) => {
    setETALoading(true);
    const idsToFetchETA = manifestsPayload
      .map((manifestItem: manifest) => {
        return manifestItem.manifestLines.length ? manifestItem.id : null;
      })
      .filter((id: string | null) => id);

    const idsToFetch = [] as any;
    const totalRoutes = manifestsPayload.length;
    const routePerGroup = Math.ceil(
      totalRoutes > 30
        ? totalRoutes / Math.ceil(totalRoutes / 10)
        : totalRoutes / 3
    );

    while (idsToFetchETA.length > 0) {
      idsToFetch.push(idsToFetchETA.splice(0, routePerGroup));
    }
    if (idsToFetch.length) {
      // Fetch etas by ids and merge into routes
      getETAsGroup(idsToFetch, manifestsPayload);
    } else {
      setETALoading(false);
    }
  };

  const getETAsGroup = async (idGroup: any[], manifestsPayload: manifest[]) => {
    let newManifests = [...manifestsPayload] as manifest[];
    await Promise.all(
      idGroup.map((ids) =>
        getRouteETAWithMapIds(ids).then((ETAs) => {
          newManifests = mergeETAsIntoRoutes(newManifests, ETAs) as manifest[];
          dispatch(replaceManifestsUpdatedETAs(newManifests));
        })
      )
    );
    setETALoading(false);
  };

  const handleSelectRouteList = (routeId: string, isStopped: boolean) => {
    setRoutesSelected(
      routesSelected.includes(routeId)
        ? routesSelected.filter((rId) => rId !== routeId)
        : routesSelected.concat(routeId)
    );
    if (isStopped) {
      setRoutesStoppedSelected(
        routesStoppedSelected.includes(routeId)
          ? routesStoppedSelected.filter((rId) => rId !== routeId)
          : routesStoppedSelected.concat(routeId)
      );
    }
  };

  const handleCheckAllRoutes = () => {
    if (
      routesSelected.length ===
      manifests.filter((mnf: manifest) =>
        CanModifyRouteByStatus.includes(mnf.status) && isHideEmptyRoute
          ? mnf.manifestLines.length
          : true
      ).length
    ) {
      setRoutesSelected(
        searchKeyword
          ? routesSelected.filter(
              (id: string) => !results.find((i: manifest) => i.id === id)
            )
          : []
      );
      setRoutesStoppedSelected(
        searchKeyword
          ? routesStoppedSelected.filter(
              (id: string) =>
                !results.find(
                  (i: manifest) =>
                    i.id === id && i.status === DeliveryStatusValue.STOPPED
                )
            )
          : []
      );
      unallocatedDocumentCount && setUnallocateChecked(false);
    } else {
      unallocatedDocumentCount && setUnallocateChecked(true);
      setRoutesSelected(
        results
          .filter((mnf: manifest) =>
            CanModifyRouteByStatus.includes(mnf.status) && isHideEmptyRoute
              ? mnf.manifestLines.length
              : true
          )
          .map((item: manifest) => item.id)
      );
      setRoutesStoppedSelected(
        results
          .filter(
            (mnf: manifest) =>
              CanModifyRouteByStatus.includes(mnf.status) &&
              mnf.status === DeliveryStatusValue.STOPPED
          )
          .map((item: manifest) => item.id)
      );
    }
  };

  // filter manifest line can check;
  const manifestCanCheck =
    (results &&
      results.length &&
      results.filter((mnf: manifest) =>
        CanModifyRouteByStatus.includes(mnf.status) && isHideEmptyRoute
          ? mnf.manifestLines.length
          : true
      )) ||
    [];
  // check all condition
  const isCheckedAll = manifestCanCheck.length
    ? routesSelected.length === manifestCanCheck.length &&
      manifestCanCheck.length !== 0
    : false;

  useEffect(() => {
    if (isHideEmptyRoute) {
      setRoutesSelected(
        routesSelected.filter((id: string) =>
          results.find(
            (mnf: manifest) => mnf.id === id && mnf.manifestLines.length
          )
        )
      );
    }
  }, [isHideEmptyRoute]);

  const timeFormat = (time?: null | string) =>
    time && time !== "00:00:00"
      ? moment(time, "HH:mm:ss").format("HH:mm")
      : null;

  // Optimize actions
  const handleOptimizeRoutes = () => {
    navigate(`/${PATH.DELIVERY._}/${PATH.DELIVERY.OPTIMIZING}`, {
      state: {
        OptimizeIds: routesSelected,
        OptimizeRoutes: routesSelected.map((id) => {
          const route = getRouteById(id);
          const routeETA = getETAByManifestId(id);
          return {
            id: route?.routeId,
            manifestId: id,
            workStartTime: timeFormat(route?.driver.workStartTime),
            workEndTime: timeFormat(route?.driver.workEndTime),
            breakStartTime: timeFormat(route?.driver.breakStartTime),
            breakEndTime: timeFormat(route?.driver.breakEndTime),
            returnToDepot: route?.driver.returnToDepot,
            excludeOptimize: false,
            totalTimeBefore: routeETA?.workingTime,
            totalDistanceBefore: routeETA?.distance,
          };
        }),
      },
    });
  };
  // Optimize actions

  // Move document to another route
  const [moveModal, setMoveModal] = useState<manifestLine>({} as manifestLine);
  const [manifestCurrent, setManifestCurrent] = useState<manifest>(
    {} as manifest
  );

  const saveEditingRoutes = useSaveEditingRoutes();
  const { isLoading: editIsSaving } = saveEditingRoutes;

  const handleMoveToAnotherRouteClick = useCallback(
    (manifestLine: manifestLine, route: manifest) => {
      setMoveModal(manifestLine);
      setManifestCurrent(route);
    },
    []
  );

  const removeManifestLine = (
    route: manifest,
    manifestLineRemove: manifestLine
  ) => {
    const newManifestLineFilter = route.manifestLines.filter(
      (manifestLine) => manifestLine.id !== manifestLineRemove.id
    );

    return newManifestLineFilter.map((r, index) => {
      return {
        ...r,
        position: route.id === manifestIds.UNALLOCATED ? r.position : index,
      };
    });
  };

  const handleMovetoTarget = (
    manifestTarget: manifest,
    positionNumber: number,
    manifestLineMoving: manifestLine
  ): manifest => {
    const manifestLines = [...manifestTarget.manifestLines];
    if (positionNumber >= 0 && manifestLineMoving) {
      manifestLines.splice(positionNumber, 0, manifestLineMoving);
    }
    const routeObj = {
      ...manifestTarget,
      manifestLines: refillManifestLinePosition(
        manifestLines,
        manifestTarget.id
      ),
    };
    return routeObj;
  };

  const handleMoveManifestLine = (
    target?: manifest,
    position?: string,
    manifestLineMoving?: manifestLine
  ) => {
    const positionNumber = (
      position ? parseInt(position) - 1 : undefined
    ) as number;

    if (manifestLineMoving && positionNumber >= 0 && target) {
      if (checkCompletedDocumentPosition(target, positionNumber)) {
        return SnackbarUtils.error(
          "This position is delivered. Please enter another position number."
        );
      }

      const isUnallocated =
        manifestLineMoving?.manifestId === manifestIds.UNALLOCATED ||
        manuallyOptimizedManifestLines.some(
          (ml) => ml.id === manifestLineMoving?.id
        );

      if (isUnallocated && previewingOptimizeGroup) {
        handleMoveUnallocated(manifestLineMoving, target, positionNumber);
      } else {
        const entryManifest: manifest = {
          ...manifestCurrent,
          manifestLines: removeManifestLine(
            manifestCurrent,
            manifestLineMoving
          ),
          polylines: "",
        };

        const updatedTargetManifest: manifest = {
          ...handleMovetoTarget(target, positionNumber, manifestLineMoving),
          polylines: "",
        };

        const manifestsMoved: manifest[] = [
          ...(isUnallocated ? [] : [entryManifest]),
          updatedTargetManifest,
        ];

        saveEditingRoutes.mutate(manifestsMoved, {
          onSuccess: (res) => {
            setETALoading(true);

            let newManifests = [...manifestsMoved];

            if (isUnallocated) {
              newManifests = manifestsMoved.map((mnf) => {
                if (mnf.id === updatedTargetManifest.id) {
                  return {
                    ...mnf,
                    manifestLines: mnf.manifestLines.map((line, index) => {
                      return {
                        ...line,
                        id: res.lineChangeList[index].id,
                        timestampManifest:
                          res.lineChangeList[index].timestampManifest,
                      };
                    }),
                  };
                }
                return mnf;
              });

              dispatch(
                moveUnallocatedAndApplyManifestLine({
                  manifestLineMoving,
                  newManifests,
                })
              );
            } else {
              // Replace manifestline without polyline
              dispatch(
                moveAndApplyManifestLine({
                  manifestsUpdate: newManifests,
                  lineChangeList: res.lineChangeList,
                })
              );
            }

            setMoveModal({} as manifestLine);
            setManifestCurrent({} as manifest);

            SnackbarUtils.success(
              `Move order <b>#${manifestLineMoving.document.saleDocumentNumber}</b> to route <b>${target?.route.name}</b> successfully.`
            );
            // refetch ETA and polyline
            getRouteETAWithMapIds(newManifests.map((mnf) => mnf.id)).then(
              (ETAs) => {
                const manifestsMovedReplaceETA = mergeETAsIntoRoutes(
                  newManifests,
                  ETAs
                ) as manifest[];
                // Replace polyline
                dispatch(
                  moveAndApplyManifestLine({
                    manifestsUpdate: manifestsMovedReplaceETA,
                    lineChangeList: res.lineChangeList,
                  })
                );
                setETALoading(false);
              }
            );
          },
        });
      }
    }
  };

  const handleMoveUnallocated = (
    manifestLineMoving: manifestLine,
    manifestTarget: manifest,
    positionNumber: number
  ) => {
    if (
      !manuallyOptimizedManifestLines.some(
        (ml) => ml.id === manifestLineMoving.id
      )
    ) {
      setManuallyOptimizedManifestLines((prev) => [
        ...prev,
        manifestLineMoving,
      ]);
    }

    if (previewingOptimizeGroup) {
      dispatch(
        moveUnallocatedManifestLineInOptimizeGroup({
          manifestLineMoving,
          targetManifest: manifestTarget,
          targetPosition: positionNumber,
          jobId: previewingOptimizeGroup?.jobId,
        })
      );
    } else if (optimizingGroups.length === 0) {
      // TODO: move unallocated manifest line to another route
    }

    setMoveModal({} as manifestLine);
    setManifestCurrent({} as manifest);
  };

  const alertEditedMsg =
    "There is a pending optimization. Leaving the page or changing date will discard the optimized results. Are you sure?";

  useEffect(() => {
    if (optimizingGroups.length) {
      const arr = [] as manifest[];
      optimizingGroups.map((optimizeGroup: any) => {
        if (optimizeGroup.solution) {
          optimizeGroup.manifests.map((mnf: manifest) => {
            // const slt = optimizeGroup.solution.find(
            //   (solution: solution) => solution.manifestId === mnf.id
            // );
            arr.push(mnf);
            // arr.push({
            //   ...mnf,
            //   distance: slt.totalDistanceAfter,
            //   workingTime: slt.totalTimeAfter,
            //   manifestLines:
            //     slt?.visits.map((vs: visits) => {
            //       return {
            //         ...vs.manifestLine,
            //         position: vs.position - 1,
            //         arrivalTime: hoursToISO(vs.arrivalTime),
            //       };
            //     }) || [],
            //   polylines: slt?.polyline,
            // });
            return mnf;
          });
        }
        return optimizeGroup;
      });
      setManifestsOptimizing(arr);
    } else {
      setManifestsOptimizing([] as manifest[]);
    }

    window.onbeforeunload = (event) => {
      if (optimizingGroups.length) {
        const e = event || window.event;
        if (e) {
          e.returnValue = alertEditedMsg;
        }
        return alertEditedMsg;
      }
    };
  }, [optimizingGroups]);

  const handleRefreshList = () => {
    if (optimizingGroups.length) {
      setModalConfirmCancel("refreshList");
    } else {
      fetchManifestList(date);
    }
  };

  const handleEditRoutesFirst = () => {
    if (optimizingGroups.length) {
      setModalConfirmCancel("edit");
    } else {
      handleEditRoutes();
    }
  };

  const handleChangeDateFirst = (date: any) => {
    if (optimizingGroups.length) {
      setModalConfirmCancel(date);
    } else {
      handleChangeDate(date);
    }
  };

  const handleCancelOptimize = () => {
    if (modalConfirmCancel === "refreshList" || modalConfirmCancel === "") {
      fetchManifestList(date);
    }
    if (modalConfirmCancel === "edit") {
      handleEditRoutes();
    }
    if (
      modalConfirmCancel &&
      !["refreshList", "edit"].includes(modalConfirmCancel)
    ) {
      handleChangeDate(modalConfirmCancel);
    }
    setModalConfirmCancel("");
  };

  return (
    <>
      <div className="pb-5 flex items-center">
        <Common.Input
          wrapClassName="2xl:lg:w-[26.375rem] mr-3"
          iconRight={Icon.Search}
          iconType="stroke"
          disabled={loading}
          placeholder="Search customer, route, driver name..."
          value={searchKeyword}
          onChange={(e: any) => setSearchKeyword(e.target.value)}
          onClear={() => setSearchKeyword("")}
        />
        <div className="max-w-[12.5rem]">
          <Common.DatePicker
            disabled={loading}
            selected={startDate}
            onChange={handleChangeDateToFetch}
            onSelect={handleChangeDateFirst}
          />
        </div>
        <span data-tip="Refresh manifest list" className="ml-2">
          <Common.Button
            onClick={handleRefreshList}
            disabled={loading}
            iconLeft={Icon.Reload}
            iconType="stroke"
            id="btnRefreshManifestList"
          />
        </span>
        <StorageAddress />

        <Common.Button
          onClick={handleOptimizeRoutes}
          iconLeft={Icon.Split}
          outline
          data-tip="Select the route and click this button to optimize the way for your route"
          disabled={
            !routesSelected.length ||
            (routesStoppedSelected.length >= 1 && routesSelected.length > 1) ||
            !user.isRouteOptimisationAvailable
          }
          className="whitespace-nowrap"
        >
          Optimize routes
        </Common.Button>

        <Common.Button
          className="ml-3 whitespace-nowrap"
          iconLeft={Icon.Edit}
          outline
          onClick={handleEditRoutesFirst}
          disabled={!routesSelected.length || isETALoading}
        >
          Edit routes
        </Common.Button>
      </div>
      <div className="flex">
        <div className="w-[26.375rem] mr-4 relative">
          <div className="flex items-center mb-1 h-[1.5rem]">
            <Common.Checkbox
              label="ROUTES"
              id="ROUTES"
              ipSize="md"
              disabled={isETALoading || !manifestCanCheck.length}
              wrapClassName={clsx("ml-4", !manifests?.length && "hidden")}
              checked={
                unallocatedDocumentCount
                  ? isCheckedAll && unallocateChecked
                  : isCheckedAll
              }
              onChange={handleCheckAllRoutes}
            />
            <Common.Checkbox
              label="Hide routes without order"
              id="hideEmptyRoute"
              ipSize="md"
              wrapClassName={clsx(
                manifests?.length && "ml-4",
                !manifests?.length && "hidden"
              )}
              checked={isHideEmptyRoute}
              onChange={() => setHideEmptyRoute(!isHideEmptyRoute)}
            />
          </div>

          <DeliveryList
            date={date}
            manifests={results}
            isETAsLoading={isETALoading}
            routesChecked={routesSelected}
            onCheckRoute={handleSelectRouteList}
            searchKeyword={searchKeyword}
            unallocatedDocumentCount={unallocatedDocumentCount}
            unallocateChecked={unallocateChecked}
            onCheckUnallocate={setUnallocateChecked}
            onChangeDriver={setChangeDriverModal}
            onSwap={setSwapModal}
            onMove={setManifestMoveModal}
            onMoveUnallocated={handleMoveToAnotherRouteClick}
            onPreviewOptimizeGroup={setPreviewingOptimizeGroup}
            onCancelOptimize={handleCancelOptimize}
          />
        </div>
        <div className="flex-1 rounded bg-neutral-10 flex items-center justify-center relative">
          <div className="z-0 w-full h-full">
            <GoogleMap
              storageAddress={storageAddress}
              manifests={manifestsToDisplayOnMap}
              onMoveRoute={handleMoveToAnotherRouteClick}
              manifestIdsCanMove={manifestIdsCanMove} // disable change route when optimizing
            />
          </div>
        </div>
      </div>

      <ModalOptimize
        open={isOpenOptimizeModal}
        onClose={() => setOpenOptimizeModal(false)}
        onSubmit={handleSubmitOptimize}
      />

      <ModalMoveRouteEditScreen
        manifestData={manifestCurrent}
        // Remove unallocate data in first index of route
        manifests={manifests}
        manifestLineData={moveModal}
        open={Boolean(moveModal?.id)}
        onClose={() => setMoveModal({} as manifestLine)}
        onMoveItem={(target, position) =>
          handleMoveManifestLine(target, position, moveModal)
        }
        isMoving={editIsSaving}
        previewingOptimizeGroup={previewingOptimizeGroup}
      />

      <ModalChangeDriver
        open={Boolean(isChangeDriverModal?.id)}
        currentData={isChangeDriverModal}
        onClose={() => setChangeDriverModal({} as manifest)}
        onSubmit={() => {}}
        manifests={manifests}
      />

      <ModalSwap
        open={Boolean(isSwapModal?.id)}
        currentData={isSwapModal}
        onClose={() => setSwapModal({} as manifest)}
        onSubmit={() => {}}
        manifests={manifests}
        onCompleted={handleRefreshList}
      />

      <ModalManifestMove
        open={Boolean(isManifestMoveModal?.id)}
        currentData={isManifestMoveModal}
        onClose={() => setManifestMoveModal({} as manifest)}
        onSubmit={() => {}}
        manifests={manifests}
        onCompleted={handleRefreshList}
        date={startDate}
      />

      <ModalInform
        open={Boolean(modalConfirmCancel)}
        type="warning"
        icon={<Icon.Alert size="24" className="fill-white" />}
        onClose={() => {}}
        title="Unsaved changes detected"
        description={alertEditedMsg}
        groupBtn={
          <div className="text-[1rem] font-semibold text-left flex justify-center mt-8">
            <Common.Button outline color="gray" onClick={handleCancelOptimize}>
              Cancel optimize
            </Common.Button>
            <Common.Button
              className="ml-3"
              color="blue"
              onClick={() => {
                setModalConfirmCancel("");
              }}
            >
              Continue optimize
            </Common.Button>
          </div>
        }
      />
    </>
  );
};
