import { DeliveryStatusLabel, Icon } from "@/components";
import { useEffect } from "react";
import clsx from "clsx";
import ReactTooltip from "react-tooltip";
import { manifestLine } from "../models";
import { addressGenerate, timeFormat, timeStopFormat } from "@/utils";
import moment from "moment";
import {
  DeliveryLineStatusValue,
  TIME_FORMAT,
} from "@/configuration/globalVariable";

interface Props {
  className?: string;
  data: manifestLine;
  isUnallocated?: boolean;
}

export const DestinationItem = ({ className, data, isUnallocated }: Props) => {
  useEffect(() => {
    ReactTooltip.rebuild();
    return () => {
      ReactTooltip.hide();
    };
  }, []);
  const isCompleted = data.deliveryStatus === DeliveryLineStatusValue.COMPLETED;

  return (
    <div className={clsx("px-3 py-2 flex", className)}>
      <div>
        <div
          className={clsx(
            isCompleted ? "bg-green" : "bg-neutral-10",
            "w-5 h-5 rounded-full text-neutral-50 font-semibold flex items-center justify-center"
          )}
        >
          {isCompleted ? (
            <Icon.Checked size="12" className="fill-white" />
          ) : isUnallocated ? (
            "✕"
          ) : (
            data.position + 1
          )}
        </div>
      </div>
      <div className="flex-1 ml-4">
        <div className="font-semibold">
          <span className="text-blue">#{data.document.saleDocumentNumber}</span>{" "}
          {data.document.customerTradingName
            ? ` - ${data.document.customerTradingName}`
            : ""}
          {isCompleted && data.deliveryStatus && (
            <DeliveryStatusLabel
              className="ml-2"
              isManifestLine
              status={data.deliveryStatus}
            />
          )}
        </div>
        <div className="flex justify-between mt-2">
          <div className="flex items-center">
            <div data-tip="Earliest time - Latest time">
              <Icon.DeliveryTime size="12" className="fill-neutral-40" />
            </div>
            <span className="ml-1.5 text-neutral-70">
              {timeFormat(data.earliest, true)} -{" "}
              {timeFormat(
                data.latest === "00:00:00" ? "23:59:00" : data.latest,
                true
              )}
            </span>
          </div>
          <div className="flex items-center">
            <div data-tip="Stop time">
              <Icon.Stock size="12" className="fill-neutral-40" />
            </div>
            <span className="ml-1.5 text-neutral-70">
              {data.stopTime != null &&
                data.stopTime >= 0 &&
                timeStopFormat(data.stopTime)}
            </span>
          </div>
          <div className="flex items-center">
            <div className="Estimated time of arrival">
              <Icon.Clock size="12" className="fill-blue-80" />
            </div>
            <span className="ml-1.5 text-blue-80">
              {data?.arrivalTime
                ? moment(data?.arrivalTime).format(TIME_FORMAT.ETA_TIME)
                : "--:--"}
            </span>
          </div>
        </div>
        <div className="flex mt-2">
          <div className="mt-1" data-tip="Delivery address">
            <Icon.Location size="12" className="fill-neutral-40" />
          </div>
          <div className="ml-1.5 text-neutral-70">
            {addressGenerate(
              data.document.deliveryAddressLine1,
              data.document.deliveryAddressLine2,
              data.document.deliverySuburb,
              data.document.deliveryState,
              data.document.deliveryPostCode
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
