/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import { useEffect, useMemo, useState } from "react";
import ReactTooltip from "react-tooltip";
import {
  ModalContent,
  Card,
  Common,
  Icon,
  CustomScrollbar,
} from "@/components";
import { GoogleMap } from "./Map";
import { OptimizedReviewItem } from "./OptimizedReviewItem";
import { OptimizedReviewDetail } from "./OptimizedReviewDetail";
import clsx from "clsx";
import {
  manifest,
  manifestLine,
  optimizeInfo,
  solution,
  storageAddress,
  unserved,
} from "../models";
import { useAppSelector, useAppDispatch } from "@/sharedStore/hooks";
import { storageAddressSelector } from "../providers/deliveryManifest/selector";
import { useSubmitOptimizeRoutes } from "../services/deliveryOptimize";
import { SnackbarUtils } from "@/utils";
import { groupUnallocatedManifest, mergeManifestOptimize } from "../utils";
import { fetchAllManifestByDateAsync } from "../providers/deliveryManifest/sliceServices";
import moment from "moment";
import { TIME_FORMAT } from "@/configuration/globalVariable";
import { clearManifest } from "../providers/deliveryManifest/slice";
import { useLocation, useNavigate } from "react-router-dom";
import { manifestIds } from "../configVariable";
import { useSaveEditingRoutes } from "../services/deliveryManifest";

interface Props {
  open: boolean;
  onClose: () => void;
  onDiscard: () => void;
  onSubmit: () => void;
  onMoveUnallocated: (manifestLine: manifestLine, route: manifest) => void;
  solutions: solution[];
  unserved: unserved[];
  manifests: manifest[];
  optimizeInfo: optimizeInfo[];
  manuallyChanged: boolean;
}

export const ModalOptimized = ({
  open,
  onClose,
  onDiscard,
  onSubmit,
  onMoveUnallocated,
  solutions,
  unserved,
  manifests,
  optimizeInfo,
  manuallyChanged,
}: Props) => {
  const submitOptimizeRoutes = useSubmitOptimizeRoutes();

  const saveManuallyOptimization = useSaveEditingRoutes();

  const [isOpenDetail, setOpenDetail] = useState(false);
  const [detailData, setDetailData] = useState<manifest>({} as manifest);

  const storageAddress = useAppSelector(
    storageAddressSelector
  ) as storageAddress;

  useEffect(() => {
    ReactTooltip.rebuild();
    return () => {
      ReactTooltip.hide();
    };
  }, []);

  const unallocatedManifest = useMemo(() => {
    return manifests.length && unserved?.length
      ? groupUnallocatedManifest(
          unserved.map((unserved) => {
            return {
              ...unserved.manifestLine,
              latitude: unserved.latitude,
              longitude: unserved.longitude,
            } as manifestLine;
          }),
          manifests[0].optimizingId
        )
      : undefined;
  }, [unserved, manifests]);

  const optimizedManifests = useMemo(() => {
    return manifests.map((manifest) => {
      const slt = solutions.find(
        (solution) => solution.manifestId === manifest.id
      ) as solution;
      const opInfo = optimizeInfo.find(
        (opInfoItem) => opInfoItem.manifestId === manifest.id
      ) as optimizeInfo;
      return mergeManifestOptimize(manifest, slt, opInfo);
    });
  }, [manifests, solutions, optimizeInfo]);

  const openDetailModal = (
    solution: solution,
    manifest: manifest,
    opInfo: optimizeInfo
  ) => {
    const manifestDetailData = mergeManifestOptimize(
      manifest,
      solution,
      opInfo
    ) as manifest;
    setDetailData(manifestDetailData);
    setOpenDetail(true);
  };

  const refetchManifests = () => {
    window.manifest_refreshManifestList();
  };

  const handleApply = () => {
    if (manuallyChanged) {
      saveManuallyOptimization.mutate(
        optimizedManifests.map((manifest) => {
          return {
            ...manifest,
            polylines: "",
            manifestLines: manifest.manifestLines.map((manifestLine) => {
              return {
                ...manifestLine,
                manifestId: manifest.id,
              };
            }),
          };
        }),
        {
          onSuccess: (res) => {
            if (res.errorMessage) {
              SnackbarUtils.error("Applying optimized routes failed.");
            } else {
              onSubmit();
              refetchManifests();
            }
          },
          onError: () => {
            SnackbarUtils.error("Applying optimized routes failed.");
          },
        }
      );
    } else {
      submitOptimizeRoutes.mutate(
        {
          solution: solutions,
          unserved,
          deliveryDate: manifests[0].deliveryDate,
        },
        {
          onSuccess: (res) => {
            if (res.errorMessage) {
              SnackbarUtils.error("Applying optimized routes failed.");
            } else {
              onSubmit();
              refetchManifests();
            }
          },
          onError: () => {
            SnackbarUtils.error("Applying optimized routes failed.");
          },
        }
      );
    }
  };

  return (
    <ModalContent open={open} panelClassWidth="w-full">
      <Card
        title={`Optimized ${manifests.length} Route${
          manifests.length > 1 ? "s" : ""
        }`}
        className={clsx(isOpenDetail && "hidden")}
      >
        <div className="flex space-x-4 p-4 h-[calc(100vh-11.625rem)]">
          <div className="w-[26.5rem] flex flex-col">
            {Boolean(unserved.length) && (
              <div className="border border-yellow bg-yellow-light flex py-2.5 px-4 rounded mb-2 items-center">
                <div>
                  <Icon.InfoFill className="fill-yellow" />
                </div>
                <span className="text-sm px-4">
                  Don&apos;t have enough route to deliver all orders.
                  <br />
                  <span className="font-semibold text-yellow-dark">
                    {unserved.length} unallocated orders
                  </span>{" "}
                  is waiting for allocating.
                </span>
                <div className="ml-auto">
                  <Common.ViewDetailBtn
                    onClick={() => {
                      setDetailData(unallocatedManifest!);
                      setOpenDetail(true);
                    }}
                  />
                </div>
              </div>
            )}
            <div className="flex-1">
              <CustomScrollbar>
                {manifests.map((manifest: manifest, index: number) => {
                  const slt = solutions.find(
                    (sl: any) => sl.manifestId === manifest.id
                  ) as solution;
                  const opInfo = optimizeInfo.find(
                    (opInfoItem) => opInfoItem.manifestId === manifest.id
                  ) as optimizeInfo;
                  return (
                    <OptimizedReviewItem
                      key={index}
                      data-id={manifest.manifestId}
                      onOpenDetail={() =>
                        openDetailModal(slt, manifest, opInfo)
                      }
                      isFirst={!index}
                      manifest={manifest}
                      solution={slt}
                      optimizeInfo={opInfo}
                    />
                  );
                })}
              </CustomScrollbar>
            </div>
          </div>
          <div className="flex-1 rounded bg-neutral-10 flex items-center justify-center relative">
            <div className="z-0 w-full h-full">
              <GoogleMap
                storageAddress={storageAddress}
                manifests={
                  unallocatedManifest
                    ? [...optimizedManifests, unallocatedManifest]
                    : optimizedManifests
                }
                onMoveRoute={onMoveUnallocated}
                manifestIdsCanMove={[manifestIds.UNALLOCATED]}
              />
            </div>
          </div>
        </div>
        <div className="shadow-overline px-4 py-3 flex space-x-2 items-center">
          <div className="flex-1 flex mr-4">
            <Common.Button
              onClick={onClose}
              color="transparent"
              disabled={submitOptimizeRoutes.isLoading}
            >
              Close
            </Common.Button>
          </div>
          <Common.Button onClick={onDiscard} outline color="red">
            Discard
          </Common.Button>
          <Common.Button
            isLoading={
              submitOptimizeRoutes.isLoading ||
              saveManuallyOptimization.isLoading
            }
            disabled={
              submitOptimizeRoutes.isLoading ||
              saveManuallyOptimization.isLoading
            }
            onClick={handleApply}
          >
            Apply
          </Common.Button>
        </div>
      </Card>

      {isOpenDetail && (
        <OptimizedReviewDetail
          onCloseDetail={() => setOpenDetail(false)}
          data={detailData}
        />
      )}
    </ModalContent>
  );
};
