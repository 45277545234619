import { useState } from "react";
import clsx from "clsx";
import { Card, Common, Icon } from "@/components";
import { GoogleMap } from "./Map";
import { DestinationItem } from "./DestinationItem";
import { manifest, storageAddress } from "../models";
import { timeFormat } from "@/utils";
import { storageAddressSelector } from "../providers/deliveryManifest/selector";

import { DestinationStorageItem } from "./DestinationStorageItem";
import { useAppSelector } from "@/sharedStore/hooks";
import { isUnallocatedManifest } from "../utils";

interface Props {
  onCloseDetail: () => void;
  data: manifest;
}

export const OptimizedReviewDetail = ({ onCloseDetail, data }: Props) => {
  const [isHideDetails, setHideDetails] = useState(false);

  const isUnallocated = isUnallocatedManifest(data);

  const storageAddress = useAppSelector(
    storageAddressSelector
  ) as storageAddress;

  return (
    <Card
      title={
        <div className="flex items-center">
          <button className="mr-2" onClick={onCloseDetail}>
            <Icon.Back size="14" className="fill-neutral-80" />
          </button>
          <span>{data?.route.name}</span>
          {!isUnallocated && (
            <div>
              <div className="w-1.5 h-1.5 rounded-full bg-neutral-30 mx-3" />
            </div>
          )}
        </div>
      }
      middleHeaderContent={
        !isUnallocated && (
          <div className="flex items-center">
            <div className="text-neutral-70 text-sm">
              <div className="text-xs text-neutral-50 optimize-review-detail">
                Start time
              </div>
              {timeFormat(data?.startTime)}
            </div>
            <Icon.TimeTo size="12" className="fill-neutral-40 mx-2.5" />
            <div className="text-neutral-70 text-sm">
              <div className="text-xs text-neutral-50">End time</div>
              {timeFormat(data?.endTime)}
            </div>

            <div className="text-neutral-70 text-sm ml-10">
              <div className="text-xs text-neutral-50">Driver break period</div>
              {timeFormat(data?.opInfo?.breakStartTime)} -{" "}
              {timeFormat(data?.opInfo?.breakEndTime)}
            </div>
          </div>
        )
      }
      // rightHeaderContent={
      //   <div>
      //     <Common.Checkbox ipSize="md" checked label="Apply for the future" />
      //   </div>
      // }
    >
      <div className="flex p-4 h-[calc(100vh-7.875rem)]">
        <div className={clsx("w-[26.5rem] mr-4", isHideDetails && "hidden")}>
          <div className="flex items-center mb-4">
            <div className="text-hd5 font-semibold flex-1">Destinations</div>
            <Common.Button outline onClick={() => setHideDetails(true)}>
              Hide details
            </Common.Button>
          </div>
          <div className="max-h-[calc(100vh-13.125rem)] overflow-y-auto">
            {!isUnallocated && (
              <DestinationStorageItem
                routeInfo={data}
                manifestETALoading={false}
                isTop={true}
              />
            )}
            {data?.manifestLines.map((manifest, index) => (
              <DestinationItem
                key={index}
                data-id={manifest.manifestId}
                className={clsx(!(index % 2) && "bg-neutral-5")}
                data={manifest}
                isUnallocated={isUnallocated}
              />
            ))}
            {!isUnallocated && data?.opInfo?.returnToDepot && (
              <DestinationStorageItem
                routeInfo={data}
                manifestETALoading={false}
                isTop={false}
                className={clsx(
                  !(data?.manifestLines.length % 2) && "bg-neutral-5"
                )}
              />
            )}
          </div>
        </div>
        <div className="flex-1 rounded bg-neutral-10 flex items-center justify-center relative">
          {isHideDetails && (
            <div className="absolute top-3 left-3 z-10">
              <Common.Button outline onClick={() => setHideDetails(false)}>
                Show details
              </Common.Button>
            </div>
          )}

          <div className="ralative z-0 w-full h-full">
            <GoogleMap
              isFromOptimize={true}
              storageAddress={storageAddress}
              manifests={[data]}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};
